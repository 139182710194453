import { render, staticRenderFns } from "./FaqCard.vue?vue&type=template&id=600e98f0&scoped=true&lang=pug"
import script from "./FaqCard.vue?vue&type=script&lang=js"
export * from "./FaqCard.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "600e98f0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AccordionItem: require('/opt/build/repo/components/AccordionItem.vue').default})
