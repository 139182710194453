
export default {
  name: 'AccordionItem',
  props: ['title', 'titleClass', 'imgPath'],
  data() {
    return {
      isActive: false
    }
  }
}
